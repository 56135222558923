<template>
  <vx-card>
    <div class="vx-row mb-6">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
      >Back
      </vs-button>
    </div>

    <div class="flex md:flex-row">
      <div class="w-full pr-6 md:basis-1/2">
        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>B-ED ID</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="code" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Warehouse Code</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="warehouseCode" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Counting Date</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="countingDate" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Note</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-textarea class="w-full bg-grey-light" :value="note" disabled readonly/>
          </div>
        </div>

      </div>

      <div class="w-full md:basis-1/2">
        <div class="mb-6 vx-row">
          <vs-divider style="width: 50%; margin-left: 10%">
            List Attachment
          </vs-divider>
          <div
            class="vx-row mb-3 mt-6 w-4/4"
            style="width: 50%; margin-left: 10%"
          >
            <table class="vs-table vs-table--tbody-table">
              <template>
                <tr
                  class="tr-values vs-table--tr tr-table-state-null selected"
                  v-bind:key="tr.NameFile"
                  v-for="tr in fileAttachment"
                >
                  <td class="td vs-table--td">{{ tr.FileName }}</td>
                  <td class="td vs-table--td">
                    <vx-tooltip text="Show" v-if="tr.Path != ''">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-eye"
                        @click.stop="handleShowAttachment(tr.FileUrl)"
                      />
                    </vx-tooltip>
                  </td>

                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>

    <vs-table
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      stripe
      border
      description
      :sst="true"
    >
      <template slot="thead">
        <vs-th>SKU</vs-th>
        <vs-th>Warehouse Area</vs-th>
        <vs-th>Type</vs-th>
        <vs-th>HU</vs-th>
        <vs-th>Batch / ED</vs-th>
        <vs-th>New Batch External</vs-th>
        <vs-th>New ED</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="`${tr.SkuCode} - ${tr.ItemName}`">
            {{ `${tr.SkuCode} - ${tr.ItemName}` }}
          </vs-td>
          <vs-td :data="`${tr.WarehouseAreaCode} - ${tr.WarehouseAreaName}`">
            {{ `${tr.WarehouseAreaCode} - ${tr.WarehouseAreaName}` }}
          </vs-td>
          <vs-td :data="tr.AreaTypeTypes">
            {{ tr.AreaTypeTypes }}
          </vs-td>
          <vs-td :data="tr.Unit">
            {{ tr.Unit }}
          </vs-td>
          <vs-td :data="`${tr.BatchExternal} / ${formatDate(tr.ExpiredDate)}`">
            {{ `${tr.BatchExternal} / ${formatDate(tr.ExpiredDate)}` }}
          </vs-td>
          <vs-td :data="tr.NewBatch">
            {{ tr.NewBatch }}
          </vs-td>
          <vs-td :data="formatDate(tr.NewExpiredDate)">
            {{ formatDate(tr.NewExpiredDate) }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

  </vx-card>
</template>

<script>
import Attachment from "@/views/stockOpname/batch-ed/components/Attachment.vue";
import moment from "moment";

export default {
  components: {Attachment},
  data() {
    return {
      id: this.$route.query.id ? parseInt(this.$route.query.id) : null,
      code: null,
      warehouseCode: null,
      countingDate: null,
      note: null,
      fileAttachment: [],
      table: this.tableDefaultState(),
    }
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        transactionDate: null,
      };
    },
    handleBack() {
      this.$vs.loading();
      this.$router.go(-1);
      this.$vs.loading.close();
    },
    getData() {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/batch-ed/detail/" + this.id)
        .then((resp) => {
          if (resp.code === 200) {
            this.code = resp.data.batch_ed.Code;
            this.warehouseCode = resp.data.batch_ed.WarehouseCode + " - " + resp.data.batch_ed.WarehouseName;
            this.countingDate = moment(resp.data.batch_ed.CountingDate).format('YYYY-MM-DD');
            this.note = resp.data.batch_ed.Note;
            this.fileAttachment = resp.data.batch_ed_attach;
            this.table.data = resp.data.batch_ed_line;
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
          }
          this.$vs.loading.close();
        });
    },
    handleShowAttachment(file) {
      window.open(file, "_blank");
    },
    formatDate(date) {
      return date ? moment(date).format('YYYY-MM-DD') : '';
    },
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
};
</script>
